import React, { useState, useEffect  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";

import { login } from "./auth.slice";
import { clearMessage } from "./message.slice";


const LoginForm = () => {
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const initialValues = {
    username: "",
    password: "",
  };

  const handleLogin = (formValue) => {
    const { username, password } = formValue;
    setLoading(true);

    dispatch(login({ username, password }))
      .unwrap()
      .then(() => {
        navigate("/user/surveys");
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  if (isLoggedIn) {
    return <Navigate to="/user/surveys" />;
  }

  return (
    <div className="col-md-12 login-form">
      <div className="card card-container">
        <Formik
          initialValues={initialValues}
          onSubmit={handleLogin}
        >
          <Form>
          <legend>Login</legend>
          {message && <div className="alert alert-danger" role="alert">{message}</div>}
            <div className="form-group">
              <label htmlFor="username">Email</label>
              <Field name="username" type="text" placeholder="admin@example.com" className="form-control" />
              <ErrorMessage
                name="username"
                component="div"
                className="alert alert-danger"
              />
            </div>

            <div className="form-group">
              <label htmlFor="password">Password</label>
              <Field name="password" type="password" placeholder="123" className="form-control" />
              <ErrorMessage
                name="password"
                component="div"
                className="alert alert-danger"
              />
            </div>

            {loading ?
            <button className="btn btn-primary" type="submit" disabled>Loading...</button> :
            <button className="btn btn-primary" type="submit">Login</button>}

          </Form>
        </Formik>
      </div>

    </div>
  );
};

export default LoginForm;
