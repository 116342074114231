import React from 'react';

import { SurveyNavBar } from './SurveyNavBar';
import './EditSurveyPage.css';

export const UserSurveyPage = () => {

  return (
    <div>
      <div>
        <SurveyNavBar/>
      </div>
    </div>
  )
}


