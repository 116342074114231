import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'

import { updateQuestion } from '../../edit.slice'

export const TextEditor = ({ props }) => {

    const titleRef = useRef(null)
    const placeholderRef = useRef(null)

    const dispatch = useDispatch()

    const updateTitle = (questionId) => {
      let payload = {
        title: titleRef.current.value,
        placeholder: placeholderRef.current.value,
      };
      dispatch(updateQuestion({ questionId, payload }))
    }

    return (
        <div>
          <form>
            <div className="form-group">
              <label>Field Label</label>
              <input type="text" className="form-control input-sm"
                     value={props.title}
                     ref={titleRef}
                     onChange={() => updateTitle(props._id)}/>
            </div>
            <div className="form-group">
              <label>Placeholder</label>
              <input type="text" className="form-control input-sm"
                     value={props.placeholder}
                     ref={placeholderRef}
                     onChange={() => updateTitle(props._id)}/>
            </div>
          </form>
        </div>
    )
}
