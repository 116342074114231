import React from 'react';
import { useSelector, useDispatch } from 'react-redux'

import {
  assembleSurvey,
  getQuestionOrder,
  updateSurvey
} from '../edit.slice'
import './EditFooter.css';

const PanelButton = ( onClick ) => {
    return (
      <button className="btn btn-sm btn-primary" type="button"  onClick={e => {
        e.preventDefault();
        onClick();
      }}>
      Save Survey
      </button>
    )
}

export const EditFooter = () => {

    let isUpdateSuccess = false

    const survey  = useSelector((state) => state.edit.survey);
    const surveyStatus = useSelector((state) => state.edit.status)
    const question_order = useSelector(getQuestionOrder)

    const dispatch = useDispatch();

    const save = () => {
      dispatch(updateSurvey(assembleSurvey({survey, question_order})));
    }

    if (surveyStatus == 'update_success'){
      isUpdateSuccess = true
    }

    return (
      <div className="EditFooter">
        <button as="a" className="btn btn-sm btn-primary" type="button"  onClick={e => {
          e.preventDefault();
          save();
        }}>
          {isUpdateSuccess ? <span className="glyphicon glyphicon-ok"></span>  : ''}
          Save Survey
        </button>
      </div>
    )
}
