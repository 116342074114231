import React from 'react';
import { QuestionWrapper } from './QuestionPreviewWrapper';

export const QuestionList = ({ survey, question_order, questions, current_question_id }) => {

  let orderedQuestions = question_order.map(id => questions[id]);

    return (
      <ul className="list-unstyled">
        {orderedQuestions.map((question, index) => {
            return (
                <li key={question._id}>
                  <QuestionWrapper
                      survey={survey}
                      question_order={question_order}
                      question={question}
                      showUp={index !== 0}
                      showDown={index !== orderedQuestions.length - 1}
                      isActive={current_question_id === question._id}
                  />
                </li>
            )
        })}
      </ul>
    )
}
