import React from 'react';
import { useDispatch } from 'react-redux'

import { QuestionDescriptions, InitQuestions } from '../../../constants/Questions';
import { addQuestion } from '../edit.slice'
import './QuestionListPanel.css';

export const QuestionListPanel = () => {

    const dispatch = useDispatch()

    const onSelectQuestion = (questionType) => {
      let newQuestion = InitQuestions[questionType]();
      let questionId = newQuestion._id
      dispatch(addQuestion({ questionId, newQuestion }))
    };

    return (
      <div className="QuestionListPanel">
        <h4>Questions</h4>
        <div className="QuestionList">
          {QuestionDescriptions.map(question => {
            return <a key={question.text} href="#" className="btn btn-default btn-sm" onClick={(e) => {
              e.preventDefault();
              onSelectQuestion(question.type)
            }}>{question.text}</a>
          })}
        </div>
      </div>
    )
}
