import React from 'react';
import { Field } from "formik";

export const MultiChoice = ({ props }) => {

    return (
        <div>
          <h3 className="question-title">{props.title}</h3>
          <div>
          {props.options.map((option, index) => {
            return (
                <div key={option._id} className="radio">
                  <label>
                    <Field type="radio" name={props._id} value={option._id} />
                    {option.content}
                  </label>
                </div>
            )
          })}
          </div>
        </div>
    )
}
