import React, { Suspense } from 'react'
import { useParams } from 'react-router-dom';

import { ResultReport } from './ResultReport';
import { SurveyNavBar } from '../../features/surveys/SurveyNavBar';

export const SurveyReportPage = () => {

  let { surveyId } = useParams();

  return (
    <div className="container">
      <div>
        <SurveyNavBar/>
      </div>
      <Suspense fallback={<p>Loading result report graphs...</p>}>
        {/* <ReportFilter /> */}
        <ResultReport surveyId={surveyId}/>
      </Suspense>
    </div>
  )
}
