import React from 'react';
import Toggle from 'react-toggle';
import { Button } from 'react-bootstrap';
import {
  useLocation,
  useParams,
} from 'react-router-dom';

import { SurveyNavBar } from './SurveyNavBar';
import './OverviewPage.css';

export const OverviewSurveyPage = () => {

  let checked = true;
  let vibesURL = process.env.REACT_APP_VIBES_ENDPOINT;
  let { surveyId } = useParams();
  let location = useLocation();

  const surveyLink = (id) => {
    return `${vibesURL}/vibes/${id}`;
  }

  const surveyUrl = (id) => {
    return `/vibes/${id}`;
  }

  return (
    <div className="container OverviewPage">
      <div>
      <div>
        <SurveyNavBar/>
      </div>
    </div>
      <div className="row">
        <div className="col-md-6">
          <ul className="list-unstyled card">
            <li className="clearfix">
              <div className="title">
                Create At
              </div>
              <div className="content">
                2016-09-08 22:22:22
              </div>
            </li>
            <li className="clearfix">
              <div className="title">
                Is Or Not Collecting Survey
              </div>
              <div className="content">
                <Toggle
                    checked={checked}
                    aria-labelledby="biscuit-label"
                    onChange={() => {
                      console.log('ToDo: survey.receive_results = !survey.receive_results;');
                      console.log('updateSurvey(survey);');
                    }} />
              </div>
            </li>
            <li className="clearfix">
              <div className="title">
                Share The Result
              </div>
              <div className="content">
                <Toggle
                    checked={checked}
                    aria-labelledby="biscuit-label"
                    onChange={() => {
                      console.log('survey.publish_results = !survey.publish_results;');
                      console.log('updateSurvey(survey);');
                    }} />
              </div>
            </li>
            <li className="clearfix">
              <div className="title">
                Link of The Survey
              </div>
              <div className="content">
                <a href={surveyLink(surveyId)} title={surveyUrl(surveyId)} target="_blank">{surveyUrl(surveyId)}</a>
              </div>
            </li>
          </ul>
        </div>

        <div className="col-md-6">
          <ul className="list-unstyled card">
            <li className="clearfix">
              <div className="title">
                Current Result Count
              </div>
              <div className="content">
                2
              </div>
            </li>
            <li className="clearfix">
              <div className="title">
                Result Submit Today
              </div>
              <div className="content">
                0
              </div>
            </li>
            <li className="clearfix">
              <div className="title">
                Last Result Submit At
              </div>
              <div className="content">
                2016-09-08 22:22:22
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="row">
        <footer className="col-md-12">
          <Button
              className="btn btn-sm btn-danger"
              >Delete Survey</Button>
        </footer>
      </div>
    </div>
  )
}
