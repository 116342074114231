import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import api from '../../api';
import { QuestionTypes } from '../../constants/Questions';

const initialState = {
  isLoading: false,
  error: '',
  survey: [],
  results: []
}

export const fetchSurvey = createAsyncThunk(
  'data/fetchSurvey',
  async (surveyId) => {
    const response = await api.fetchSurvey(surveyId)

    return response
})

export const fetchResults = createAsyncThunk(
  'data/fetchResults',
  async (surveyId) => {
    const response = await api.fetchResults(surveyId)

    return response
})

const dataSlice = createSlice({
  name: 'data',
  initialState,
  extraReducers: {
    [fetchSurvey.fulfilled]: (state, action) => {
      state.survey = action.payload
      state.isLoading = false
      state.error = ''
    },
    [fetchSurvey.pending]: (state, action) => {
      state.isLoading = true
      state.error = ''
    },
    [fetchSurvey.rejected]: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
    [fetchResults.fulfilled]: (state, action) => {
      state.results = action.payload
    },
  },
})

const { reducer } = dataSlice;

export default reducer;

export const getSurvey = (state) => state.data.survey

export const getSurveyQuestions = (state) => state.data.survey.questions ?? [];

export const getFetchStatus = (state) => state.data.isLoading

export const getErrorStatus = (state) => state.data.error

export const canReportTypes = [QuestionTypes.CHECKBOXES, QuestionTypes.DROPDOWN, QuestionTypes.MULTI_CHOICE];

const checkboxResult = (result, questionId) => {
  let checkedResult = Object.entries(result.result).filter(([key, value]) => value === true);
  let filtered = checkedResult.flat().filter(e => typeof e === 'string' && e.startsWith(questionId))
  if (filtered.length > 0) {
    return filtered;
  }
}

const pickCheckboxAnswer = (result, questionId) => {
  let answers = checkboxResult(result, questionId)
  if (answers) {
    return answers.map(a => a.toString().slice(26, 51));
  }
}

const resultToText = {
  [QuestionTypes.CHECKBOXES]: (question, result) => {
    return question.options.filter(option => result.find(e => e === option._id)).map(option => option.content).join(", ");
  },
  [QuestionTypes.MUTLI_LINE_TEXT]: (question, result) => {
    return result;
  },
  [QuestionTypes.SINGLE_LINE_TEXT]: (question, result) => {
    return result;
  },
  [QuestionTypes.DROPDOWN]: (question, result) => {
    return question.options.find(option => option._id === result).content;
  },
  [QuestionTypes.MULTI_CHOICE]: (question, result) => {
    return question.options.find(option => option._id === result).content;
  }
};

export const hasFilterMap = (state) => {
  let reportFilter = state.reportFilter;
  let hasFilterMap = {};
  Object.keys(reportFilter).forEach(questionId => {
    let answer = reportFilter[questionId];
    let hasFilter = Object.keys(answer).some(answerId => {
      if (typeof answer[answerId] === 'boolean') {
        return answer[answerId];
      } else if (typeof answer[answerId] === 'object') {
        return Object.keys(answer[answerId]).some(k => answer[answerId][k]);
      }
      return null;
    });
    hasFilterMap[questionId] = hasFilter;
  });
  return hasFilterMap;
};

export const resultsToGrid = (state) => {
  let { survey, results } = state.data;

  if (!survey || !survey.questions) {
    return {
      columns: [],
      headers: [],
      results: []
    };
  }

  let columns = survey.questions.map((question, index) => {
    return {
      columnName: question._id,
      displayName: question.title
    };
  });

  let headers = survey.questions.map((question, index) => {
    return {
      key: question._id,
      label: question.title
    };
  });

  let questionTypeMap = {};
  survey.questions.forEach(question => {
    questionTypeMap[question._id] = question.type
  });

  let textResults = results.map((result, index) => {
    let resultMap = {
      _id: result._id,
      _rev: result._rev
    };

    survey.questions.forEach(question => {
      let questionResult = result.result[question._id] || checkboxResult(result, question._id);
      resultMap[question._id] = questionResult ? resultToText[question.type](question, questionResult) : '';
    });

    return resultMap;
  });

  return {
    columns,
    headers,
    results: textResults
  };
}

export const resultsToReport = (state) => {
  let { survey, results, reportFilter } = state.data;

  if (!survey || !survey.questions) {
    return {
      reportResult: [],
      results: []
    };
  }


  let filterMap = {}
  // let filterMap = hasFilterMap(state);

  // let filtedResults = results.filter(result => {
  //   return every(Object.keys(result.result), questionId => {
  //     if (!filterMap[questionId]) return true;
  //     let answer = result.result[questionId];
  //     if (typeof answer === 'string') {
  //       console.log('ANSWER');
  //       console.log(answer);
  //       console.log(reportFilter[questionId][answer]);
  //       return reportFilter[questionId][answer];
  //     } else if (typeof answer === 'object') {
  //       return Object.keys(answer).some(subKey => reportFilter[questionId][subKey]);
  //     } else {
  //       return true;
  //     }
  //   });
  // });

  // TODO: Uncomment above, fix when you add Report filter
  let filtedResults = results

  let reportResult = survey.questions
      .filter(q => canReportTypes.indexOf(q.type) !== -1)
      .map(question => {
        let id = question._id;
        let optionMap = {};
        question.options.forEach(o => {
          optionMap[o._id] = {
            content: o.content,
            count: 0
          };
        });
        filtedResults.forEach(result => {
          let questionAnswer = result.result[id] || pickCheckboxAnswer(result, id);
          if (typeof questionAnswer === 'string') {
            optionMap[questionAnswer].count++;
          } else if (typeof questionAnswer === 'object') {
            questionAnswer.forEach(answer => optionMap[answer].count++);
          }
        });
        return {
          _id: id,
          title: question.title,
          stats: Object.keys(optionMap).map(key => {
            return {
              name: optionMap[key].content,
              value: optionMap[key].count
            };
          })
        };
      });

  return {
    reportResult: reportResult,
    results: results
  };
};
