import React from 'react';
import { NewSurvey } from './NewSurvey';
import { SurveyList } from './SurveyList';
import './SurveysPage.css';

export const SurveysPage = () => {

  return (
      <div className="container UserSurveysPage">
        <NewSurvey />
        <SurveyList/>
      </div>
  )
}
