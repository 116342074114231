import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'

import { OptionField } from './OptionField';
import { updateQuestion } from '../../edit.slice'
import newId  from '../../../../util/idGenerator';

export const MultipleChoiceEditor = ({ props }) => {

    const titleRef = useRef(null)
    const optionsRefs = props.options.map(() => useRef(null))

    const dispatch = useDispatch()

    const updateTitle = (questionId) => {
      let payload = {title: titleRef.current.value}
      dispatch(updateQuestion({ questionId, payload }))
    }

    const updateOptions = (questionId, optionId, index) => {
      let payload = {
        options: [
          ...props.options.slice(0, index),
          {
            _id: optionId,
            content: optionsRefs[index].current.value
          },
          ...props.options.slice(index + 1)
        ]
      }
      dispatch(updateQuestion({ questionId, payload }))
    }

    const cloneOptions = (questionId, index) => {
      let payload = {
        options: [
          ...props.options.slice(0, index + 1),
          {_id: newId(), content: 'content'},
          ...props.options.slice(index + 1)]
      }
      dispatch(updateQuestion({ questionId, payload }))
    }

    const removeOptions = (questionId, index) => {
      let payload = {
        options: [
          ...props.options.slice(0, index),
          ...props.options.slice(index + 1)]
      }
      dispatch(updateQuestion({ questionId, payload }))
    }

    return (
        <div>
          <form>
            <div className="form-group">
              <label>Field Label</label>
              <input type="text" className="form-control input-sm"
                     value={props.title}
                     ref={titleRef}
                     onChange={() => updateTitle(props._id)}/>
            </div>
            <div className="form-group">
              <label>Options</label>
              {props.options.map((option, index) => {
                return <OptionField
                    canRemove={index !== 0}
                    key={option._id}
                    index={index}
                    content={option.content}
                    ref={optionsRefs[index]}
                    onChange={() => {
                      updateOptions(props._id, option._id, index)
                    }}
                    onClone={() => {
                      // cloneOptions(props._id, index)
                    }}
                    onRemove={() => {
                      // removeOptions(props._id, index)
                    }}
                    />
              })}
            </div>
          </form>
        </div>
    )
}
