import React, { forwardRef } from 'react';

import './OptionField.css';

 export const OptionField = forwardRef((props, ref) => {

  return (
      <div className="OptionField clearfix">
        <input type="text" className="form-control input-sm" ref={ref} name={props.key} value={props.content} onChange={props.onChange}/>
        <div className="btn-group">
          <button className="btn btn-sm btn-default" type="button" onClick={props.onClone}>
            <span className="glyphicon glyphicon-plus" aria-hidden="true"></span>
          </button>
          {props.canRemove ? <button className="btn btn-sm btn-default" type="button" onClick={props.onRemove}>
            <span className="glyphicon glyphicon-minus" aria-hidden="true"></span>
          </button> : ''}
        </div>
      </div>
    )
  });
