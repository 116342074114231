import React, { Suspense } from 'react'
import { useParams } from 'react-router-dom';

import { SurveyNavBar } from '../../features/surveys/SurveyNavBar';
import { SurveyPreview } from './SurveyPreview';
import { EditTab } from './EditTab';
import { EditFooter } from './EditPanel/EditFooter';
import './EditSurveyPage.css';

export const EditSurveyPage = () => {

  let { surveyId } = useParams();

  return (
    <div className="EditSurveyPage container">
      <div>
        <SurveyNavBar/>
      </div>
      <div className="clearfix EditPanel">
        <div className="col-md-8 Main">
          <Suspense fallback={<p>Loading survey edit page...</p>}>
            <SurveyPreview surveyId={surveyId}/>
          </Suspense>
        </div>
        <div className="col-md-4 Sidebar">
          {/* <AutoAffix> */}
            <div>
              <EditTab/>
              <EditFooter/>
            </div>
          {/* </AutoAffix> */}
        </div>
      </div>
    </div>
  )
}
