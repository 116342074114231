import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import SurveyListView from './SurveyListView';
import { selectAllSurveys, fetchSurveys } from './surveys.slice'

export const SurveyList = () => {
  const surveys = useSelector(selectAllSurveys)
  const currentUser = useSelector((state) => state.auth.user);
  const surveyStatus = useSelector((state) => state.surveys.status)
  const error = useSelector((state) => state.surveys.error)
  const dispatch = useDispatch()

  useEffect(() => {
    if (currentUser && surveyStatus === 'idle') {
      dispatch(fetchSurveys(currentUser))
    }
  }, [surveyStatus, currentUser, dispatch])

  if (surveyStatus === 'loading') {
    return <div>Loading...</div>
  }
  
  if (surveyStatus === 'failed') {
    return <div>Load Error</div>
  }

  if (surveyStatus === 'succeeded') {
    return (
      <SurveyListView surveys={surveys}/>
    )
  }  
}
