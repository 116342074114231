import {
  BrowserRouter,
  Routes,
  Route,
  Redirect
} from 'react-router-dom'
import './App.css';

import { TopNavBar } from './features/base/TopNavBar';
import { LoginPage } from './features/auth/LoginPage';
import { RegisterPage } from './features/auth/RegisterPage';
import { SurveysPage } from './features/surveys/SurveysPage';
import { UserSurveyPage } from './features/surveys/UserSurveyPage';
import { OverviewSurveyPage } from './features/surveys/OverviewSurveyPage';
import { EditSurveyPage } from './features/edit/EditSurveyPage';
import { SurveyDataPage } from './features/data/SurveyDataPage';
import { SurveyReportPage } from './features/report/SurveyReportPage';
import { FeedbackPage } from './features/surveys/FeedbackPage';
import { ProtectedRoute } from './features/auth/ProtectedRoute';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <TopNavBar />
        <Routes>
          <Route path="/login" element={<LoginPage/>} />
          <Route path="/register" element={<RegisterPage/>} />
          <Route path="/surveys/:surveyId" element={<FeedbackPage/>} />

          <Route path="/user/surveys" element={
            <ProtectedRoute><SurveysPage/></ProtectedRoute>} />
          <Route path="/user/surveys/:surveyId" element={
            <ProtectedRoute><UserSurveyPage/></ProtectedRoute>} />
          <Route path="/user/surveys/:surveyId/overview" element={
            <ProtectedRoute><OverviewSurveyPage/></ProtectedRoute>} />
          <Route path="/user/surveys/:surveyId/edit" element={
            <ProtectedRoute><EditSurveyPage/></ProtectedRoute>} />
          <Route path="/user/surveys/:surveyId/data" element={
            <ProtectedRoute><SurveyDataPage/></ProtectedRoute>} />
          <Route path="/user/surveys/:surveyId/report" element={
            <ProtectedRoute><SurveyReportPage/></ProtectedRoute>} />
        </Routes>
      </BrowserRouter>
      </div>
  )
}

export default App