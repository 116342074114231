import React, { useEffect, Suspense } from 'react'
import { Alert } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { Survey } from './Survey'
import './SurveyPage.css';

export const FeedbackPage = () => {

  let { surveyId } = useParams();

  return (
      <div className="container">
        {/* {this.props.survey.receive_results ? '' : <Alert bsStyle="danger">Do not collection result any more</Alert>} */}
        <div className="row SurveyPage">
          <div className="col-md-8 col-md-offset-2 survey">
            <Suspense fallback={<p>Loading survey page...</p>}>
              <Survey surveyId={surveyId}/>
            </Suspense>
          </div>
        </div>
      </div>
  )
}
