import React, { Component } from 'react';

import RegisterForm from './RegisterForm';

export const RegisterPage = () => {  

  return (
      <div className="col-md-4 col-md-offset-4" style={{marginTop: '40px'}}>
        <RegisterForm />
      </div>
  )
}
