import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { fetchSurvey, fetchResults, resultsToReport, resultsToGrid } from '../data/data.slice'
import { ReportItem } from './ReportItem';
import './Report.css';

export const ResultReport = ({ surveyId }) => {
  
  let { reportResult, results } = useSelector(resultsToReport)
  
  const dispatch = useDispatch()

  useEffect(() => {
    if (surveyId) {
      dispatch(fetchSurvey(surveyId))
      dispatch(fetchResults(surveyId))
    }
  }, [surveyId, dispatch])

  return (
    <div className="Report">
      <h4>Total Data: {results.length}</h4>
      {reportResult.map((d, index) => {
        return (
            <ReportItem data={d} key={d._id}/>
        )
      })}
    </div>
  );
}
