import React from 'react';
import { useSelector, useDispatch } from 'react-redux'

import {
  activeQuestion,
  sortQuestionUp,
  sortQuestionDown,
  cloneQuestion,
  removeQuestion,
  switchTab
 } from './edit.slice'
import { QuestionTypes } from '../../constants/Questions';
import { SingleLineText } from './questions/SingleLineText';
import { MultiLineText } from './questions/MultiLineText';
import { MultiChoice } from './questions/MultiChoice';
import { Checkboxes } from './questions/Checkboxes';
import { Dropdown } from './questions/Dropdown';
import tabTypes from '../../constants/TabTypes';

const questionMap = {
  [QuestionTypes.SINGLE_LINE_TEXT]: (props) => {
    return <SingleLineText props={props}/>
  },
  [QuestionTypes.MUTLI_LINE_TEXT]: (props) => {
    return <MultiLineText props={props}/>
  },
  [QuestionTypes.MULTI_CHOICE]: (props) => {
    return <MultiChoice props={props}/>
  },
  [QuestionTypes.CHECKBOXES]: (props) => {
    return <Checkboxes props={props}/>
  },
  [QuestionTypes.DROPDOWN]: (props) => {
    return <Dropdown props={props}/>
  }
};

export const QuestionWrapper = ({ survey, question_order, question, showUp, showDown, isActive }) => {

    const dispatch = useDispatch()

    const onActive = (questionId) => {
      dispatch(activeQuestion({ survey, questionId }));
      if (questionId === 'header')
        dispatch(switchTab(tabTypes.EDIT_SURVEY_TAB));
      else
        dispatch(switchTab(tabTypes.EDIT_QUESTION_TAB));
    };

    const onUp = (questionId) => {
      dispatch(sortQuestionUp({ survey, question_order, questionId }));
    };

    const onDown = (questionId) => {
      dispatch(sortQuestionDown({ survey, question_order, questionId }));
    };

    const onClone = (questionId) => {
      dispatch(cloneQuestion({ survey, questionId }));
    };

    const onRemove = (questionId) => {
      dispatch(removeQuestion({ survey, questionId }));
    };
    
    let QuestionComponent = questionMap[question.type](question);
    let classNames = isActive ? 'active question clearfix' : 'question clearfix';

     return (
         <div onClick={() => { onActive(question._id) }} className={classNames}>
           {QuestionComponent}
           <div className="btn-group pull-right">
             {showUp ?
                 <button
                     type="button"
                     className="btn btn-sm btn-primary"
                     onClick={(e) => {
                       onUp(question._id);
                     }}>
                   <span className="glyphicon glyphicon-arrow-up"></span>
                 </button> : ''
             }
             {showDown ? <button
                 type="button"
                 className="btn btn-sm btn-primary"
                 onClick={() => onDown(question._id)}>
               <span className="glyphicon glyphicon-arrow-down"></span>
             </button> : ''}
             <button
                 type="button"
                 className="btn btn-sm btn-primary"
                 onClick={(e) => { e.stopPropagation(); onClone(question._id); }}>
               <span className="glyphicon glyphicon-plus"></span>
             </button>
             <button
                 type="button"
                 className="btn btn-sm btn-primary"
                 onClick={() => onRemove(question._id)}>
               <span className="glyphicon glyphicon-minus"></span>
             </button>
           </div>
         </div>
     )
}
