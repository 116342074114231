import React from 'react';

export const Checkboxes = ({ props }) => {

    return (
        <div>
          <h3 className="question-title">{props.title}</h3>
          <div>
          {props.options.map((option, index) => {
            return (
                <div className="checkbox" key={index}>
                  <label>
                    <input type="checkbox" disabled name={`${props._id}[]`} value={option._id}/>
                    {option.content}
                  </label>
                </div>
            )
          })}
          </div>
        </div>
    )
}

