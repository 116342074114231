import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import api from '../../api';

const initialState = {
  surveys: [],
  status: 'idle',
  error: null,
}

export const fetchSurveys = createAsyncThunk(
  'surveys/fetchSurveys',
  async (user) => {
    const response = await api.fetchUserSurveys(user);
    return response
})

const surveysSlice = createSlice({
  name: 'surveys',
  initialState,
  extraReducers: {
    [fetchSurveys.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.surveys = action.payload
    },
    [fetchSurveys.pending]: (state) => {
      state.status = 'loading'
    },
    [fetchSurveys.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
  },
})

const { reducer } = surveysSlice;

export default reducer;

export const selectAllSurveys = (state) => state.surveys.surveys

export const selectSurveyById = (state, surveyId) =>
  state.surveys.surveys.find((survey) => survey.id === surveyId)
