import React, { useCallback } from "react";
import { useDispatch, useSelector  } from 'react-redux'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { logout } from "../auth/auth.slice";
import './TopNavBar.css';
import logo from "../../images/logo.png";

export const TopNavBar = () => {

  let currentUser = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const loginView = (currentUser) => {
    return (
      <Nav>
        <NavDropdown title={currentUser.username} id="basic-nav-dropdown">
          <NavDropdown.Divider />
          <NavDropdown.Item eventKey="4.4" href="/login" onClick={logOut}>Logout</NavDropdown.Item>
        </NavDropdown>
      </Nav>
    )
  }

  const unLoginView = () => {
    return (
        <Nav className="justify-content-end">
          <Nav.Item>
            <Nav.Link eventKey={1} href="/login">Login</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey={1} href="/register">Register</Nav.Link>
          </Nav.Item>
      </Nav>
    )
  }

  return (
      <Navbar bg="light" expand="lg">
        <Container className="TopNavBar" fluid>
        <Navbar.Brand href="/">
            <img
              src={logo}
              alt="VibeViews"
            />
          </Navbar.Brand>
          <Navbar.Collapse>
            {currentUser ? loginView(currentUser) : unLoginView()}
          </Navbar.Collapse>
        </Container>
      </Navbar>
  )
}

