import React from 'react';
import LoginForm from './LoginForm';

export const LoginPage = () => {

  return (
      <div className="col-md-4 col-md-offset-4" style={{marginTop: '40px'}}>
        <LoginForm/>
      </div>
  )
}

