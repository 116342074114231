import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import tabTypes from '../../constants/TabTypes';
import { QuestionListPanel } from './EditPanel/QuestionListPanel'
import { EditQuestionPanel } from './EditPanel/EditQuestionPanel'
import { EditSurveyPanel } from './EditPanel/EditSurveyPanel'
import { switchTab } from './edit.slice'
import './EditTab.css';

var classNames = require('classnames');

const tabs = [
    {
      type: tabTypes.QUESTIONS_TAB,
      text: 'Add Question',
      panel: <QuestionListPanel/>
    },
    {
      type: tabTypes.EDIT_QUESTION_TAB,
      text: 'Edit Question',
      panel: <EditQuestionPanel/>
    },
    {
      type: tabTypes.EDIT_SURVEY_TAB,
      text: 'Edit Survey',
      panel: <EditSurveyPanel/>
    }
]

export const EditTab = () => {
    // const { activeTab, onUpdateTab, tabs } = this.props;
    const activeTab  = useSelector((state) => state.edit.tab);

    const dispatch = useDispatch()

    const onUpdateTab = (tab) => {
      dispatch(switchTab(tab));
    };
    
    return (
        <div className="EditTab">
          <ul className="nav nav-pills">
            {tabs.map(tab => {
              return (
                  <TabLink
                      key={tab.type}
                      active={activeTab === tab.type}
                      text={tab.text}
                      onClick={() => { onUpdateTab(tab.type); }}>
                  </TabLink>
              );
            })}
          </ul>
          {tabs.map(tab => {
            return (
                <TabPanel
                    active={activeTab === tab.type}
                    panel={tab.panel}
                    key={tab.type}>
                </TabPanel>
            );
          })}
        </div>
    )
}

// EditTab.propTypes = {
//   tabs: PropTypes.array.isRequired,
//   activeTab: PropTypes.string.isRequired,
//   onUpdateTab: PropTypes.func.isRequired
// };

export default EditTab;

// sub component

const TabLink = ({ active, text, onClick }) => {
  let tabClass = classNames('tab-item', {active: active});
  return (
      <li role="presentation" className={tabClass}>
        <a className="btn btn-sm btn-default" href="#" onClick={e => {
          e.preventDefault();
          onClick();
        }}>
          {text}
        </a>
      </li>
  )
};

// TabLink.propTypes = {
//   active: PropTypes.bool.isRequired,
//   children: PropTypes.node.isRequired,
//   onClick: PropTypes.func.isRequired
// };

const TabPanel = ({ active, panel }) => {
  if (!active) {
    return (<div></div>);
  } else {
    return (
        <div>
          {panel}
        </div>
    );
  }
};

// TabPanel.propTypes = {
//   active: PropTypes.bool.isRequired,
//   children: PropTypes.node.isRequired
// };
