import React from 'react';

export const SurveyHeader = ({ title, subTitle, isActive, onActive}) => {

    return (
      <header className={isActive ? 'active' : ''} >
        <h3>{title}</h3>
        <p>{subTitle}</p>
      </header>
    )
}

