import React from 'react';
import { Field } from "formik";

export const Checkboxes = ({ props }) => {

    return (
        <div>
          <h3 className="question-title">{props.title}</h3>
          <div>
          {props.options.map((option, index) => {
            return (
                <div className="checkbox" key={index}>
                  <label>
                    {/* <Field type="checkbox" name={`${props._id}[${option._id}]`} value={option._id}  /> */}
                    <Field type="checkbox" name={props._id} value={option._id}  />
                    {option.content}
                  </label>
                </div>
            )
          })}
          </div>
        </div>
    )
}

