import React from 'react'
import { useSelector } from 'react-redux'

import { QuestionTypes } from '../../../constants/Questions';
import { MultipleChoiceEditor } from './question_editors/MultipleChoiceEditor';
import { TextEditor } from './question_editors/TextEditor';
import { getActiveQuestion } from '../edit.slice'

import './EditQuestionPanel.css';

const questionEditorMap = {
  [QuestionTypes.CHECKBOXES]: (props) => {
    return <MultipleChoiceEditor props={props}/>
  },
  [QuestionTypes.DROPDOWN]: (props) => {
    return <MultipleChoiceEditor props={props}/>
  },
  [QuestionTypes.MULTI_CHOICE]: (props) => {
    return <MultipleChoiceEditor props={props}/>
  },
  [QuestionTypes.SINGLE_LINE_TEXT]: (props) => {
    return <TextEditor props={props}/>
  },
  [QuestionTypes.MUTLI_LINE_TEXT]: (props) => {
    return <TextEditor props={props}/>
  }
};

export const EditQuestionPanel = () => {

  const question  = useSelector(getActiveQuestion);

  let mapped = '';
  if (questionEditorMap[question.type]) {
    mapped = questionEditorMap[question.type](question);
  }
  return (
      <div className="EditQuestionPanel">
        {mapped}
      </div>
  )
}
