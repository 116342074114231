import React from 'react';

import { QuestionTypes } from '../../constants/Questions';
import { SingleLineText } from './questions/SingleLineText';
import { MultiLineText } from './questions/MultiLineText';
import { MultiChoice } from './questions/MultiChoice';
import { Checkboxes } from './questions/Checkboxes';
import { Dropdown } from './questions/Dropdown';

const questionMap = {
  [QuestionTypes.SINGLE_LINE_TEXT]: (props) => {
    return <SingleLineText props={props}/>
  },
  [QuestionTypes.MUTLI_LINE_TEXT]: (props) => {
    return <MultiLineText props={props}/>
  },
  [QuestionTypes.MULTI_CHOICE]: (props) => {
    return <MultiChoice props={props}/>
  },
  [QuestionTypes.CHECKBOXES]: (props) => {
    return <Checkboxes props={props}/>
  },
  [QuestionTypes.DROPDOWN]: (props) => {
    return <Dropdown props={props}/>
  }
};

export const QuestionWrapper = ({ question }) => {

    var QuestionComponent = questionMap[question.type](question);
    var classNames = 'question clearfix';

    return (
        <div className={classNames}>
          {QuestionComponent}
        </div>
    )
}
