import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CSVLink } from "react-csv";
import { Button } from 'react-bootstrap';

import { fetchSurvey, fetchResults, resultsToGrid } from './data.slice'

export const ResultGrid = ({ surveyId }) => {

  let { columns, headers, results } = useSelector(resultsToGrid)
  const dispatch = useDispatch()
  useEffect(() => {
    if (surveyId) {
      dispatch(fetchSurvey(surveyId))
      dispatch(fetchResults(surveyId))
    }
  }, [surveyId, dispatch])


  return (
    <div className="ResultGrid">
      <div className="grid-wrapper">
        <div className="toolbar">
          <Button className='' bsStyle="primary" bsSize="xsmall" >Delete</Button>
          <CSVLink
            data={results}
            headers={headers}
            filename={"results.csv"}
            className="btn btn-primary"
            target="_blank"
          >
            Export
          </CSVLink>
        </div>

        <table className="table table-condensed table-hover table-bordered">
          <thead>
          <tr>
            <th className="select-box">
              <input
                  type="checkbox"
              />
            </th>
            <th className="index">#</th>
            {columns.map(col => {
              return <th key={col.id}>{col.displayName}</th>
            })}
          </tr>
          </thead>
          <tbody>
          {results.map((result, index) => {
            return (
                <tr
                    key={result._id}
                >
                  <td className="select-box">
                    <input
                        type="checkbox"
                    />
                  </td>
                  <td className="index">{index + 1}</td>
                  {columns.map((col, index) => {
                    return <td key={index}>{result[col.columnName]}</td>
                  })}
                </tr>
            )
          })}
          </tbody>
        </table>
      </div>
    </div>
  )
}


