import React from 'react';

import './EditSurveyPanel.css';

export const EditSurveyPanel = () => {

  // update() {
  //   this.props.onUpdate({
  //     title: this.title.value,
  //     subTitle: this.subTitle.value
  //   });
  // }

    // let { title, subTitle } = this.props;
    let { title, subTitle } = [ 'title placeholer', 'subTitle placeholder' ] ;

    return (
      <div className="EditSurveyPanel">
        <div className="form-group">
          <label>Title</label>
          <input
              type="text"
              className="form-control"
              value={title}
              // ref={(input) => { this.title = input }}
              // onChange={this.update.bind(this)}
              />
        </div>
        <div className="form-group">
          <label htmlFor="">SubTitle</label>
          <textarea
              className="form-control"
              value={subTitle}
              // ref={input => { this.subTitle = input }}
              // onChange={this.update.bind(this)}
              >
              </textarea>
        </div>
      </div>
    )
}

// EditSurveyPanel.propTypes = {
//   onUpdate: PropTypes.func.isRequired,
//   title: PropTypes.string.isRequired,
//   subTitle: PropTypes.string.isRequired
// };

// export default EditSurveyPanel;
