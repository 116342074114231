import React from 'react';
import { NewSurveyView } from './NewSurveyView';

export const NewSurvey = () => {

  let isLoading = false;

  return (
    <NewSurveyView
      isLoading={isLoading}
    />
  )
}
