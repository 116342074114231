import React from 'react';

export const SingleLineText = ({ props }) => {

    return (
        <div>
          <h3 className="question-title">{props.title}</h3>
          <div className="form-group">
            <input type="text" className="form-control" placeholder={props.placeholder} name={props._id} disabled/>
          </div>
        </div>
    )
}
