import { configureStore } from '@reduxjs/toolkit';

import authReducer from "./features/auth/auth.slice";
import messageReducer from "./features/auth/message.slice";
import surveysReducer from "./features/surveys/surveys.slice"
import dataReducer from "./features/data/data.slice"
import editReducer from "./features/edit/edit.slice"

export const store = configureStore({
    reducer: {
        auth: authReducer,
        message: messageReducer,
        surveys: surveysReducer,
        data: dataReducer,
        edit: editReducer,
    }
});