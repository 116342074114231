import React from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export const GraphReport = ({ data, width, height }) => {

  return (
      <div>
        <BarChart data={data} width={width} height={height}>
          <XAxis dataKey="name"/>
          <YAxis/>
          <Tooltip />
          <CartesianGrid strokeDasharray="3 3"/>
          <Bar dataKey="value" fill="#337ab7" barSize={20} />
        </BarChart>
      </div>
  );
}

