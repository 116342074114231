import React from 'react';
import { Link } from 'react-router-dom'
import {
  useLocation,
  useParams,
} from 'react-router-dom';
import './SurveyNavBar.css';

export const SurveyNavBar = () => {

  let { surveyId } = useParams();
  let location = useLocation();

  return (
    <div className="SurveyNavBar">
      <div className="container">
        <ul className="nav nav-pills">
          <NavLink url={`/user/surveys`} location={location} text="Surveys"/>
          <NavLink url={`/user/surveys/${surveyId}/overview`} location={location} text="Overview"/>
          <NavLink url={`/user/surveys/${surveyId}/edit`} location={location} text="Edit"/>
          <NavLink url={`/user/surveys/${surveyId}/data`} location={location} text="Data"/>
          <NavLink url={`/user/surveys/${surveyId}/report`} location={location} text="Report"/>
        </ul>
      </div>
    </div>
);
}


const NavLink = (props) => {
  let { url, location, text } = props;
  return <li role="presentation" className={location.pathname === url ? 'active' : ''}>
    <Link to={url}>{text}</Link>
  </li>
};

