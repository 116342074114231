import React, { useEffect } from 'react'
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useSelector, useDispatch } from 'react-redux'

import { QuestionWrapper } from './QuestionWrapper';
import { fetchSurvey, getSurvey, getSurveyQuestions, getFetchStatus, getErrorStatus } from '../data/data.slice'
import { submitResult, getSubmitStatus } from '../edit/edit.slice'

export const Survey = ({ surveyId }) => {

  const dispatch = useDispatch()
  
  const isSuccess = useSelector(getSubmitStatus)
  const survey = useSelector(getSurvey)
  const questions = useSelector(getSurveyQuestions)
  const isLoading = useSelector(getFetchStatus)
  const error = useSelector(getErrorStatus)

  useEffect(() => {
    if (surveyId) {
      dispatch(fetchSurvey(surveyId))
    }
  }, [surveyId, dispatch])
  
  const handleSubmit = (result) => {
    dispatch(submitResult({surveyId, result}))
  };

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error!</div>
  }

  if (isSuccess) {
    return (
      <div>
        <h1>Submit Success!</h1>
      </div>
    )
  }

  if (!survey) {
    return <div/>;
  }
  const initialValues = {};

  questions.forEach(item => {
    if (item.type === "CHECKBOXES") {
        return (initialValues[item._id] = []);
    } else if (item.type === "SINGLE_LINE_TEXT") {
      return (initialValues[item._id] = '');
    }
  });

  return (
    <div className="Survey">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ result }) => (
          <Form>
            <header>
              <h3>{survey.title}</h3>
              <p>{survey.subTitle}</p>
            </header>
            <ul className="list-unstyled">
              {questions.map(question => {
                return <li key={question._id}><QuestionWrapper question={question}/></li>
              })}
            </ul>
            <button type="submit">Submit</button>
          </Form>
        )}
      </Formik>
    </div>
  )
}
