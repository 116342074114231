import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { SurveyHeader } from './SurveyHeader'
import { QuestionList } from './QuestionList'
import { editSurvey } from './edit.slice'
import './SurveyPreview.css';

export const isHeaderActive = (survey) => {
  return survey.current_question_id === 'header';
};

export const SurveyPreview = ({ surveyId }) => {

  const survey  = useSelector((state) => state.edit.survey);
  const question_order  = useSelector((state) => state.edit.question_order);
  const surveyStatus = useSelector((state) => state.edit.status)

  const dispatch = useDispatch()

  useEffect(() => {
    if (surveyId) {
      dispatch(editSurvey(surveyId))
    }
  }, [surveyId, dispatch])

  if (surveyStatus === 'loading') {
    return <div>Loading...</div>
  }

  if (surveyStatus === 'failed') {
    return <div>Load Error</div>
  }

  if (surveyStatus === 'succeeded') {
    return (
      <div className="SurveyPreview">
        <form>
          <SurveyHeader
            title={survey.title}
            subTitle={survey.subTitle}
            isActive={isHeaderActive(survey)}
          />
          <QuestionList
            survey={survey}
            question_order={question_order}
            questions={survey.questions}
            current_question_id={survey.current_question_id}
          />
        </form>
      </div>
    )
  }
}
