import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import api from '../../api';

const initialState = {
  survey: [],
  status: 'idle',
  isSuccess: false,
  question_order: [],
  tab: 'QUESTIONS_TAB',
  error: null,
}

export const submitResult = createAsyncThunk(
  'edit/submitResult',
  async ({surveyId, result}) => {
    const response = await api.saveResult(surveyId, result)

    return response
})

export const editSurvey = createAsyncThunk(
  'edit/editSurvey',
  async (surveyId) => {
    const response = await api.fetchSurvey(surveyId)

    return response
})

export const updateSurvey = createAsyncThunk(
  'edit/updateSurvey',
  async (survey, thunkAPI) => {
    const response = await api.updateSurvey(survey)
    thunkAPI.dispatch(editSurvey(survey.id))

    return survey
})

const editSlice = createSlice({
  name: 'edit',
  initialState,
  reducers: {
    activeQuestion(state, action) {
      const { survey, questionId } = action.payload
      state.survey = { ...survey, current_question_id: questionId }
    },
    sortQuestionUp(state, action) {
      const { survey, question_order, questionId } = action.payload
      const idx = question_order.indexOf(questionId);

      let newOrder = [...question_order]
      newOrder[idx] = newOrder[idx - 1];
      newOrder[idx - 1] = questionId;

      state.survey = { ...survey, question_order: newOrder }
      state.question_order = newOrder
    },
    sortQuestionDown(state, action) {
      const { survey, question_order, questionId } = action.payload
      const idx = question_order.indexOf(questionId);

      let newOrder = [...question_order]
      newOrder[idx] = newOrder[idx + 1];
      newOrder[idx + 1] = questionId;

      state.survey = { ...survey, question_order: newOrder }
      state.question_order = newOrder
    },
    cloneQuestion(state, action) {
      const { survey, questionId } = action.payload
      state.survey = { ...survey, current_question_id: questionId }
    },
    removeQuestion(state, action) {
      const { survey, questionId } = action.payload
      state.survey = { ...survey, current_question_id: questionId }
    },
    switchTab(state, action) {
      state.tab = action.payload
    },
    addQuestion(state, action) {
      const { newQuestion, questionId } = action.payload
      let newOrder = [...state.survey.question_order, questionId]
      state.survey = {
        ...state.survey,
        questions: {
          ...state.survey.questions,
          [questionId]: newQuestion
        },
        question_order: newOrder
      }
      state.question_order = newOrder
    },
    updateQuestion(state, action) {
      const { questionId, payload } = action.payload
      state.survey = {
        ...state.survey,
        questions: {
          ...state.survey.questions,
          [questionId]: {
            ...state.survey.questions[questionId],
            ...payload
          }
        }
      }
    },
  },
  extraReducers: {
    [editSurvey.fulfilled]: (state, action) => {
      let normalized_survey = normalizeSurvey(action.payload)
      state.question_order = normalized_survey.question_order
      state.survey = normalized_survey
      state.status = 'succeeded'
    },
    [editSurvey.pending]: (state, action) => {
      state.status = 'loading'
    },
    [editSurvey.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.error.message
    },
    [updateSurvey.fulfilled]: (state, action) => {
      state.status = 'update_success'
    },
    [submitResult.fulfilled]: (state, action) => {
      state.isSuccess = true
    },
  },
})

const { reducer, actions } = editSlice;

export const {
  activeQuestion,
  sortQuestionUp,
  sortQuestionDown,
  addQuestion,
  updateQuestion,
  cloneQuestion,
  removeQuestion,
  switchTab
} = actions
export default reducer;

const normalizeSurvey = (survey) => {
  let questions = {};
  survey.questions.forEach(question => {
    questions[question._id] = question
  });
  let question_order = survey.questions.map(question => question._id);
  return {
    ...survey,
    id: survey.id,
    title: survey.title,
    subTitle: survey.subTitle,
    questions: questions,
    question_order: question_order,
    current_question_id: '',
    original: {
      ...survey
    }
  }
};

export const assembleSurvey = ({survey, question_order}) => {
  const { id, title, subTitle, questions } = survey;
  let orderQuestions = question_order.map(questionId => questions[questionId]);
  return {
    ...survey.original,
    title,
    subTitle,
    id,
    questions: orderQuestions,
  }
};

export const getActiveQuestion = (state) => {
  let activeQuestionId = state.edit.survey.current_question_id;
  let activeQuestion = state.edit.survey.questions[activeQuestionId];
  return activeQuestion ? activeQuestion : {};
};

export const isHeaderActive = (state) => {
  return state.survey.current_question_id === 'header';
};

export const getQuestionOrder = (state) => state.edit.question_order

export const getSubmitStatus = (state) => state.edit.isSuccess
