import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { register } from "./auth.slice";
import { clearMessage } from "./message.slice";

const RegisterForm = () => {
  
  const [submitting, setSubmitting] = useState(false);

  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const initialValues = {
    username: "",
    email: "",
    password: "",
    passwordConfirm: ""
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .test(
        "len",
        "The username must be between 3 and 20 characters.",
        (val) =>
          val &&
          val.toString().length >= 3 &&
          val.toString().length <= 20
      )
      .required("This field is required!"),
    email: Yup.string()
      .email("This is not a valid email.")
      .required("Please enter your email"),
    password: Yup.string()
      .test(
        "len",
        "The password must be between 6 and 40 characters.",
        (val) =>
          val &&
          val.toString().length >= 6 &&
          val.toString().length <= 40
      )
      .required("Please enter password"),
    passwordConfirm: Yup.string()
      .label('Confirm Password')
      .required()
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  const handleRegister = (formValue) => {

    setSubmitting(true);
    let params = {
      username: formValue.username,
      email: formValue.email,
      password: formValue.password
    }

    dispatch(register(params))
      .unwrap()
      .then(() => {
        setSubmitting(false);
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleRegister}
    >
      <Form>
        <div>
          <legend>Register</legend>
          <div className="form-group">
            <label htmlFor="username">User Name</label>
            <Field name="username" type="text" className="form-control" />
            <ErrorMessage
              name="username"
              component="div"
              className="alert alert-danger"
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <Field name="email" type="email" className="form-control" />
            <ErrorMessage
              name="email"
              component="div"
              className="alert alert-danger"
            />
          </div>

          <div className="form-group">
            <label htmlFor="password">Password</label>
            <Field
              name="password"
              type="password"
              className="form-control"
            />
            <ErrorMessage
              name="password"
              component="div"
              className="alert alert-danger"
            />
          </div>

          <div className="form-group">
            <label htmlFor="Confirm Password">Confirm Password</label>
            <Field
              name="passwordConfirm"
              type="password"
              className="form-control"
            />
            <ErrorMessage
              name="passwordConfirm"
              component="div"
              className="alert alert-danger"
            />
          </div>
          {submitting ?
            <button className="btn btn-primary" type="submit" disabled>Loading...</button> :
            <button className="btn btn-primary" type="submit">Submit</button>}
        </div>
      </Form>
    </Formik>

    // {message && (
    //   <div className="form-group">
    //     <div
    //       className={successful ? "alert alert-success" : "alert alert-danger"}
    //       role="alert"
    //     >
    //       {message}
    //     </div>
    //   </div>
    // )}
  );

}

export default RegisterForm;
