import React from 'react';

export const Dropdown = ({ props }) => {
  
    return (
        <div>
          <h3 className="question-title">{props.title}</h3>
          <div className="form-group">
            <select className="form-control" name={props._id}>
              {props.options.map((option, index) => {
                return (
                    <option value={option._id}>{option.content}</option>
                )
              })}
            </select>
          </div>
        </div>
    )
}
