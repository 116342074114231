import React from 'react';
import { Link } from 'react-router-dom'
import './SurveyItem.css';

export const SurveyItem = ({ survey }) => {

  return (
    <div className="SurveyItem">
      <Link to={`/user/surveys/${survey.id}/overview/`}>
        {survey.title}
      </Link>
    </div>
  );
}

export default SurveyItem;