import React from 'react';
import { Field } from "formik";

export const MultiLineText = ({ props }) => {

    return (
        <div>
          <h3 className="question-title">{props.title}</h3>
          <div className="form-group">
            <Field component="textarea" className="form-control" placeholder={props.placeholder} name={props._id}></Field>
          </div>
        </div>
    )
}
