import React, { useEffect, useRef } from 'react'
import { useState } from 'react';

import { GraphReport } from './GraphReport';
import { TableReport } from './TableReport';

export const ReportItem = ({ data }) => {

  const [height, width] = useState(0, 0);
  const graphRef = useRef(null);

  useEffect(() => {

    setTimeout(() => {
      if (graphRef.current) {
        console.log(graphRef.current.offsetHeight);
        console.log(graphRef.current.offsetWidth);
      }
    }, 100);
  }, [graphRef])

  return (
      <div className="container">
        <div className="row">
          <div>{data.title}</div>
        </div>
        <div className="row Question" key={data.title}>
          <div className="col-md-7 GraphContainer"  ref={graphRef}>
            <GraphReport data={data.stats} width={600} height={100}/>
          </div>
          <div className="col-md-5 TableContainer">
            <TableReport data={data.stats}/>
          </div>
        </div>
      </div>
  )
}
