import React from 'react';
import SurveyItem from './SurveyItem';
import './SurveyListView.css';

const SurveyExcerpt = ({ survey }) => {
  return <li key={survey.id} className="col-md-3"><SurveyItem survey={survey}/></li>
}

const SurveyListView = ({ surveys }) => {
  
  return (
    <div className="SurveyList row">
      <ul className="list-unstyled">
        {surveys.map((survey) => (
          <SurveyExcerpt key={survey.id} survey={survey} />
        ))}
      </ul>
    </div>
  );
}

export default SurveyListView;